import api from '@admin/api/service';

export default {
	getPermissionList() {
		return api.get('/singgahsini/api/role-management/permission');
	},

	getRole(roleId) {
		return api.get(`/singgahsini/api/role-management/role/${roleId}`);
	},

	postRole(data) {
		return api.post('/singgahsini/api/role-management/role', data);
	},

	putRole(roleId, data) {
		return api.put(`/singgahsini/api/role-management/role/${roleId}`, data);
	},

	getRoleList(params) {
		return api.get('/singgahsini/api/role-management/role', { params });
	},

	deleteRole(roleId) {
		return api.delete(`/singgahsini/api/role-management/role/${roleId}`);
	},

	getMemberList(roleId) {
		return api.get(`/singgahsini/api/role-management/role/${roleId}/user`);
	},

	addMemberToRole(data) {
		return api.post('/singgahsini/api/role-management/role/user', data);
	},

	deleteMemberFromRole(roleId, memberId) {
		return api.post(
			`/singgahsini/api/role-management/role/${roleId}/user/${memberId}`,
			{
				_method: 'DELETE',
			}
		);
	},
};
