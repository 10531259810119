<template>
  <div class="role-management-roles">
    <section class="nav-title">
      <div class="back-button" @click="handleBack">
        <icon name="back" size="lg" />
      </div>
      <txt size="heading-2" class="page-title"
        >{{ isEditRole ? 'Ubah' : 'Tambah' }} Role</txt
      >
    </section>

    <section class="role-name">
      <bg-field
        label="Nama Role"
        :message="error.name ? errorMessage.existed : ''"
        :error="error.name"
      >
        <div v-if="loading.name">
          <skeleton width="362px" height="46px" />
        </div>
        <bg-input
          v-else
          placeholder="Masukkan nama role"
          class="input-name"
          v-model="roleName"
        />
      </bg-field>
    </section>

    <section class="permission-list">
      <txt size="title-5" class="mb-24">Permission</txt>
      <div v-if="loading.list">
        <div style="margin-bottom: 24px">
          <skeleton width="362px" height="24px" />
        </div>
        <div>
          <skeleton width="362px" height="24px" />
        </div>
      </div>

      <checkbox
        v-else
        class="checkbox"
        v-for="(item, index) in permissions"
        :id="`permission-list-${index}-${item.label}`"
        :key="`${item.key}-${item.id}`"
        :label="item.name"
        :value="item.id"
        v-model="checked"
      />
    </section>

    <section class="footer-buttons" v-if="!loading.list">
      <bg-button
        variant="tertiary"
        @click="handleReset"
        :disabled="disableResetButton"
        >Reset</bg-button
      >
      <bg-button
        class="ml-24"
        variant="primary"
        @click="handleSave"
        :disabled="disableSaveButton"
        :loading="loading.save"
        >Simpan</bg-button
      >
    </section>
  </div>
</template>

<script>
import roleManagementApi from '@admin_endpoints/role-management';

import {
  BgInput,
  BgField,
  BgCheckbox,
  BgButton,
  BgIcon,
  BgText,
  BgSkeleton,
} from 'bangul-vue';

export default {
  name: 'RoleManagementRoles',

  components: {
    BgInput,
    BgField,
    checkbox: BgCheckbox,
    BgButton,
    icon: BgIcon,
    txt: BgText,
    skeleton: BgSkeleton,
  },

  data() {
    return {
      loading: {
        name: false,
        list: true,
        save: false,
      },

      error: {
        name: false,
      },

      errorMessage: {
        existed: 'Nama role sudah digunakan. Masukkan nama role yang lain.',
      },

      existingRoleName: '',
      roleName: '',
      checked: [],

      permissions: [],
      showToast: false,
      toastMessage: '',
    };
  },

  computed: {
    isEditRole() {
      return Boolean(this.$route.params.roleId);
    },

    roleId() {
      return this.$route.params.roleId;
    },

    allUnchecked() {
      return this.checked.length === 0;
    },

    disableResetButton() {
      return this.allUnchecked || this.loading.save;
    },

    disableSaveButton() {
      return !this.roleName.length;
    },
  },

  async beforeMount() {
    if (this.isEditRole) {
      this.loading.name = true;
      await this.processInitGetPermissions();
      await this.processInitEditRole();
      this.loading.name = false;
    } else {
      await this.processInitGetPermissions();
    }

    this.loading.list = false;
  },

  methods: {
    async getPermissionList() {
      let result = {};
      try {
        result = await roleManagementApi.getPermissionList();
      } catch (error) {
        this.openToast(error.message);
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async getRole(roleId) {
      let result = {};
      try {
        result = await roleManagementApi.getRole(roleId);
      } catch (error) {
        this.openToast(error.message);
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async postRole(data) {
      let result = {};
      try {
        result = await roleManagementApi.postRole(data);
      } catch (error) {
        result = error.message;
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async putRole(data) {
      let result = {};
      try {
        result = await roleManagementApi.putRole(this.roleId, data);
      } catch (error) {
        result = error.message;
        console.error(error);
      }
      return Promise.resolve(result);
    },

    async processInitGetPermissions() {
      this.loading.list = true;

      const permission = await this.getPermissionList();
      const permissionData = permission.data.data;
      this.permissions = permissionData;
    },

    async processInitEditRole() {
      this.loading.name = true;

      const response = await this.getRole(this.roleId);
      const { data } = response.data;

      this.existingRoleName = data.name;
      this.roleName = data.name;

      this.checked = data.permissions.map(item => item.id);

      this.loading.name = false;
    },

    handleBack() {
      this.$router.push({ name: 'role-management' });
    },

    handleReset() {
      this.checked.splice(0, this.checked.length);
    },

    async handleSave() {
      this.loading.save = true;
      this.error.name = false;

      if (this.isEditRole) {
        const newRoleName =
          this.existingRoleName === this.roleName ? '' : this.roleName;

        const response = await this.putRole({
          role_name: newRoleName,
          permission_ids: this.checked,
        });

        if (response.data.status) {
          this.$router.push({
            name: 'role-management',
            params: {
              toastMessage: 'Perubahan berhasil disimpan.',
            },
          });
        } else if (response.data.issue.details.role_name[0].length) {
          this.error.name = true;
        } else {
          this.openToast(response);
        }
      } else {
        const response = await this.postRole({
          role_name: this.roleName,
          permission_ids: this.checked,
        });

        if (response.data.status) {
          this.$router.push({
            name: 'role-management',
            params: {
              toastMessage: 'Role berhasil disimpan.',
            },
          });
        } else if (response.data.issue.details.role_name[0].length) {
          this.error.name = true;
        } else {
          this.openToast(response);
        }
      }

      this.loading.save = false;
    },

    openToast(message) {
      this.$toast.show(message, { duration: 5 });
    },
  },
};
</script>

<style lang="scss" scoped src="./RoleManagementRoles.scss"></style>
